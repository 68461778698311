/* eslint-disable */
import {request} from '../network';

// 获取label列表-搜索模块
export function LabelType(data) {
  return request({
    method: 'get',
    url: '/activity/labelType',
    params: data
  })
}

// 获取活动列表-搜索模块
export function HotList(data) {
  return request({
    method: 'post',
    url: '/activityCenter/hotList',
    data: data
  })
}

// 获取活动详情
export function GetActiveInfo(id) {
  return request({
    method: 'get',
    url: '/activityCenter/detail/' + id,
    params: {}
  })
}
