<template>
  <div class="com-container active-info-container" v-loading="loading">
    <el-breadcrumb class="active-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/active',query:{...$store.state.query}}">活动中心</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="active-info card-box">
      <div class="active-info-cover">
        <el-image :src="activeInfo.activityCover" fit="cover"/>
      </div>
      <div class="active-info-cont">
        <div class="active-info-cont-header">
          <div class="labelList">
            <template v-if="activeInfo.activityLabel">
              <div :key="index"
                   class="label"
                   v-for="(item, index) in activeInfo.activityLabel.split(',')">
                <span> {{item}}</span>
              </div>
            </template>
          </div>

          <div class="title">{{activeInfo.name}}</div>
        </div>
        <div class="active-info-cont-txt1">
          <div class="sign-box" v-if="activeInfo.schoolName">
            <div :key="index"
                 class="sign"
                 v-for="(item, index) in activeInfo.schoolName.split(',')">{{item}}
            </div>
          </div>
          <div class="sign-box" v-if="activeInfo.subjectName">
            <div :key="index"
                 class="sign"
                 v-for="(item, index) in activeInfo.subjectName.split(',')">{{item}}
            </div>
          </div>
          <div class="sign-box" v-if="activeInfo.peopleName">
            <div :key="index"
                 class="sign"
                 v-for="(item, index) in activeInfo.peopleName.split(',')">{{item}}
            </div>
          </div>
        </div>
        <div class="active-info-cont-txt2" v-if="activeInfo.activityStartTime!=null">
          活动时间：{{formatYMD(activeInfo.activityStartTime)}}
          至 {{formatYMD(activeInfo.activityEndTime)}}
        </div>
        <div class="active-info-cont-txt3">
          <div v-if="activeInfo.bmEndTime!=null">报名截止：{{formatYMDChina(activeInfo.bmEndTime)}}</div>
          <div v-if="activeInfo.activityFee && activeInfo.activityFee!='0'">
            活动费用：￥{{activeInfo.activityFee}}
            <el-tooltip class="item" content="申请审核通过后，才可缴费参与活动具体活动费用以缴费时为准。" effect="dark" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="btn-area" v-if="((activeInfo.status==1)||
(activeInfo.status==2 && activeInfo.source==2)||
(activeInfo.status==3 || activeInfo.status==4)||
(activeInfo.status==5))&&activeInfo.activityStartTime!=null">
          <el-button :disabled="true" v-if="activeInfo.status==1">报名未开放</el-button>
          <el-button @click="signUp" v-if="activeInfo.status==2 && activeInfo.source==2">立即报名</el-button>
          <el-button :disabled="true" v-if="activeInfo.status==3 || activeInfo.status==4">报名已截止</el-button>
          <el-button :disabled="true" v-if="activeInfo.status==5">已结营</el-button>
        </div>
      </div>
    </div>
    <div class="active-area">
      <div class="left-area card-box" ref="left-area"
           v-if="(activeContent==1 && anchorList.length>0) ||
         (activeContent==2 && anchorList2.length>0)">
        <div class="left-area-box">
          <ul v-if="activeContent==1">
            <li :key="index" v-for="(item,index) in anchorList">
              <a @click="anchorSkip(item.id)">{{item.name}}</a>
            </li>
          </ul>
          <ul v-else-if="activeContent==2">
            <li :key="index" v-for="(item,index) in anchorList2">
              <a @click="anchorSkip(item.id)">{{item.name}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="center-area">
        <div :style="{
          'min-height':minHeight+'px'
        }" class=" card-box">
          <div class="center-area-title">
            <div class="center-area-title-box">
              <div :class="activeContent==1?'is-active':''"
                   @click="changeActive(1)"
                   class="center-area-title-box-item"
              >活动简章
              </div>
              <div :class="activeContent==2?'is-active':''"
                   @click="changeActive(2)"
                   class="center-area-title-box-item"
                   v-if="activeInfo.previousActivities"
              >活动回顾
              </div>
            </div>
          </div>
          <div class="center-area-cont">
            <div v-html="activeInfo.admissionsBrochure"
                 v-show="activeContent==1"></div>
            <div v-html="activeInfo.previousActivities"
                 v-show="activeContent==2"></div>
          </div>

        </div>
        <div class="card-box tips">
          <div class="title">温馨提示:</div>
          <div>
            扫码添加下方企业微信（备注选科+高考年份+姓名），一步获取更多高校营地信息！
          </div>
          <div class="img">
            <img src="../../../assets/img/active/code.png"/>
          </div>
          <div>
            声明：本文信息来源于网络，由CAMP CENTER团队（微信公众号：CAMPCENTER）排版编辑，如有侵权请及时联系管理员删除。
          </div>
        </div>
      </div>

      <div class="right-area" ref="RightArea">
        <div class="question-area card-box">
          <div class="question-area-title">
            <div class="question-area-title-box">常见问题</div>
          </div>
          <div class="question-area-cont">
            <div v-if="questionList.length>0">
              <div :key="index"
                   class="ask-answer-item"
                   v-for="(item,index) in questionList">
                <div class="ask bold">问：{{item.question}}</div>
                <div class="answer">答：{{item.answer}}</div>
              </div>
            </div>
            <el-empty description="暂无问题" v-else></el-empty>

            <div class="question-email bold">
              如有其他问题需要咨询，请发送邮件至以下邮箱 service@campcenter.cn
            </div>
          </div>
        </div>
        <div class="back-area" v-if="currActivity.length>0">
          <div class="back-area-title">当期活动</div>
          <div :key="index"
               @click="toActiveInfo(item.id)"
               class="back-area-item card-box"
               v-for="(item,index) in currActivity">
            <div class="back-area-item-cover">
              <el-image :src="item.activityCover" fit="cover"/>
            </div>
            <div class="back-area-item-cont">
              <div class="back-area-item-cont-txt">{{item.name}}</div>
              <div class="back-area-item-cont-txt">
                <div class="sign-box" v-if="item.activityLabel">
                  <div :key="index2"
                       class="sign"
                       v-for="(item2, index2) in item.activityLabel.split(',')">{{item2}}
                  </div>
                </div>
              </div>
              <div class="back-area-item-cont-txt color_3">
                报名截止时间：{{formatYMDChina(item.bmEndTime)}}
              </div>
            </div>
          </div>
        </div>
        <div class="back-area" v-if="oldActivity.length>0">
          <div class="back-area-title">往期活动</div>
          <div :key="index"
               @click="toActiveInfo(item.id)"
               class="back-area-item card-box"
               v-for="(item,index) in oldActivity">
            <div class="back-area-item-cover">
              <el-image :src="item.activityCover" fit="cover"/>
            </div>
            <div class="back-area-item-cont">
              <div class="back-area-item-cont-txt">{{item.name}}</div>
              <div class="back-area-item-cont-txt">
                <div class="sign-box" v-if="item.activityLabel">
                  <div :key="index2"
                       class="sign"
                       v-for="(item2, index2) in item.activityLabel.split(',')">{{item2}}
                  </div>
                </div>
              </div>
              <div class="back-area-item-cont-txt color_2">
                活动时间：{{formatYMD(item.activityStartTime)}}
                至 {{formatYMD(item.activityEndTime)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {GetActiveInfo} from "r/base/active";

  export default {
    name: "ActiveInfo",
    data() {
      return {
        loading: false,
        id: null,// 活动id
        activeInfo: {
          activityCover: null,
          activityLabel: null,
          name: null,
          schoolName: null,
          subjectName: null,
          activityStartTime: null,
          activityEndTime: null,
          bmEndTime: null,
          activityFee: null,
          status: null,
          source: null,
          admissionsBrochure: null,
          previousActivities: null,
        },
        questionList: [],
        currActivity: [],// 当期活动卡片列表
        oldActivity: [],// 往期活动卡片列表
        anchorList: [],
        anchorList2: [],
        activeContent: 1,
        minHeight: '318',
      }
    },
    created() {
      this.commonApi(39)
      let params = this.$route.params;
      if (params.id) {
        this.id = params.id;
      }
      this.getInfo()
    },
    methods: {
      // 立即报名按钮
      signUp() {
        this.commonApi(37)
        let allToken = window.localStorage.getItem('index-token-all');
        if (allToken) {
          this.$store.commit('setIndexIdentity', this.activeInfo.identity)
          this.$router.push({
            path: '/signUp/undefined',
            query: {
              ...this.$store.state.query,
              code: this.activeInfo.identity
            }
          });
        } else {
          this.$router.push({
            path: '/baseLogin',
            query: {
              ...this.$store.state.query,
            }
          });
        }
      },
      // 去活动详情页面（当前活动/往期活动回顾区域）
      toActiveInfo(id) {
        this.$router.push({
          path: '/active/' + id,
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // 锚点跳转方法
      anchorSkip(id) {
        document.querySelector(id).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      },
      // 选择活动简章/回顾
      changeActive(val) {
        this.commonApi(39)
        this.activeContent = val;
      },
      // 分离获取html中的锚点
      getAnchor(content) {
        if (!content) return [];
        let attrAndValueArr = content.match(/<a id=\"(.*?)\"><\/a>/g);
        let valueArr = [] // 放所有该属性的值
        let anchorList = [];
        if (attrAndValueArr && attrAndValueArr.length > 0) {
          for (let i = 0; i < attrAndValueArr.length; i++) {
            valueArr.push(attrAndValueArr[i]
              .replace(/<a id=/g, '')
              .replace(/\"/g, '')
              .replace(/><\/a>/g, ''));
          }
          valueArr.forEach((item, index) => {
            let father = document.getElementById(item).parentNode;
            let value = father.innerHTML.replace(/<(.*?)>/g, '').replace(/<\/(.*?)>/g, '');
            if (value && value.length < 30) {
              anchorList.push({
                id: '#' + item,
                name: value,
              })
            }
          })
          return anchorList;
        }
        return [];
      },
      // 获取数据
      getInfo() {
        if (this.loading) {
          return false;
        }
        this.loading = true;
        GetActiveInfo(this.id).then(res => {
          console.log(res)
          if (res.data.code != 200) {
            this.loading = false;
            return this.$message.error(res.data.msg);
          }
          this.activeContent = 1;
          this.activeInfo = res.data.data.activityDetail;
          this.questionList = res.data.data.question;
          this.currActivity = res.data.data.currActivity;
          this.oldActivity = res.data.data.oldActivity;
          setTimeout(() => {
            let content1 = this.activeInfo.admissionsBrochure;
            let content2 = this.activeInfo.previousActivities;
            this.anchorList = this.getAnchor(content1);
            this.anchorList2 = this.getAnchor(content2);
            this.minHeight = this.$refs['RightArea'].offsetHeight;
          }, 100)
          this.loading = false;
        }).catch(err => {
          console.log(err)
          this.loading = false;
        })
      },
    },
    watch: {
      '$route.params'(val) {
        console.log(val)
        if (val.id) {
          this.id = val.id;
        }
        this.getInfo();
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "a/scss/common";
  @import "a/scss/breadcrumb";

  .active-info-container {
    position: relative;
    padding: 50px;
    color: #333333;
    font-family: "PingFang SC";

    .active-breadcrumb {
      position: absolute;
      top: 0;
      left: 50px;
    }

    .bold {
      font-weight: bold;
    }

    .card-box {
      border-radius: 4px;
      background-color: #ffffff;
    }

    .active-info {
      padding: 40px 60px;
      display: flex;
      flex-flow: row;
      margin-bottom: 50px;

      .active-info-cover {
        flex: 1;
        min-height: 250px;
        max-height: 340px;
        overflow: hidden;
        border-radius: 4px;

        ::v-deep .el-image {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          transition: all .5s;
          -webkit-transition: all .5s;

          .el-image__error {
            background-color: #D8D8D8;
          }

          &:hover {
            transform: scale(1.1);
            -ms-transform: scale(1.1); /* IE 9 */
            -moz-transform: scale(1.1); /* Firefox */
            -webkit-transform: scale(1.1); /* Safari 和 Chrome */
            -o-transform: scale(1.1);
          }
        }
      }

      .active-info-cont {
        flex: 1;
        margin-left: 70px;
        display: flex;
        flex-flow: column;
        /*justify-content: stretch;*/

        div {
          margin-bottom: 5px;
        }

        .active-info-cont-header {
          /*display: flex;*/
          /*flex-flow: row;*/
          /*align-items: center;*/
          /*margin-bottom: 10px;*/
          .labelList {
            display: flex;
          }

          span {
            font-size: 14px;
            padding: 6px 14px;
            border-radius: 14px;
            color: #ffffff;
            background-color: var(--all_color);
            margin-right: 15px;
            line-height: 28px;
          }

          .title {
            font-size: 18px;
            font-weight: 500;
            line-height: 36px;
          }
        }

        .active-info-cont-txt1 {
          font-size: 14px;
          line-height: 28px;

          .sign-box {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }

            .sign {
              display: inline-block;
              padding: 0 14px;
              border-radius: 14px;
              background-color: #eeeeee;
              margin: 5px 10px 5px 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }

        }

        .active-info-cont-txt2 {
          font-size: 14px;
          color: #999999;
          line-height: 24px;
        }

        .active-info-cont-txt3 {
          font-size: 14px;
          color: #EC808D;
          line-height: 32px;
          display: flex;
          flex-flow: row;
          align-items: center;
          justify-content: space-between;

          .el-tooltip {
            color: #999999;
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .btn-area {
          margin-top: 10px;

          .el-button {
            width: 150px;
            height: 40px;
            color: #333333;
            border-color: #333333;
            background-color: #ffffff;

            &:hover {
              color: var(--all_color);
              border-color: var(--all_color);

            }

            &.is-disabled {
              color: #333333;
              border-color: #333333;
              opacity: 0.5;
            }
          }
        }

      }
    }

    .active-area {
      display: flex;
      flex-flow: row;
      align-items: self-start;
      justify-content: space-between;
      position: relative;

      > div {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      .left-area {
        flex: 1;
        min-width: 200px;

        .left-area-box {
          border-radius: 4px;
          background-color: #ffffff;
        }

        ul {
          margin: 0;
          padding: 20px 10px;
        }

        li {
          list-style: none;

          a {
            display: inline-block;
            text-decoration: none;
            color: #333333;
            width: 100%;
            font-size: 18px;
            /*height: 44px;*/
            line-height: 44px;
            margin: 10px 0;
            text-align: center;
            border-bottom: 1px solid #cccccc;
            cursor: pointer;
            border-left: 4px solid transparent;

            &:hover,
            &.is-active {
              color: var(--all_color);
              background-color: #f5f5f5;
              border-left: 4px solid var(--all_color);
            }
          }

        }

      }

      .center-area {
        flex: 4;

        .center-area-title {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          height: 50px;
          padding: 10px 20px 0;

          .center-area-title-box {
            height: 50px;
            display: flex;
            flex-flow: row;
            justify-content: left;
            border-bottom: 1px solid #cccccc;

            .center-area-title-box-item {
              margin: 0 20px;
              height: 40px;
              line-height: 40px;
              cursor: pointer;

              &:hover {
                border-bottom: 2px solid #eeeeee;
              }

              &.is-active {
                border-bottom: 2px solid var(--all_color);
              }
            }
          }
        }

        .center-area-cont {
          padding: 20px 30px;
          min-height: 218px;
        }

        .tips {
          margin-top: 30px;
          padding: 20px 30px;
          font-size: 14px;

          .title {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .img {
            display: flex;
            justify-content: center;
          }

          img {
            width: 150px;
            margin: 10px 0;
          }
        }
      }

      .right-area {
        flex: 1.5;
        min-width: 320px;

        .question-area {

          .question-area-title {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 50px;
            padding: 10px 20px 0;

            .question-area-title-box {
              border-bottom: 1px solid #cccccc;
            }
          }

          .question-area-cont {
            padding: 10px 25px 20px;

            .ask-answer-item {
              padding: 10px 5px;
              font-size: 14px;
              line-height: 30px;
              border-bottom: 1px solid #cccccc;
            }

            ::v-deep .el-empty {
              padding: 10px 0;

              .el-empty__image {
                width: 100px;
              }
            }

            .question-email {
              font-size: 12px;
              line-height: 20px;
              margin-top: 20px;
            }
          }
        }

        .back-area {
          .back-area-title {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            line-height: 50px;
            padding: 10px 20px 0;
          }

          .back-area-item {
            padding: 15px 10px;
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-bottom: 10px;

            .back-area-item-cover {
              flex: 1;
              height: 88px;
              overflow: hidden;
              border-radius: 4px;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                transition: all .5s;
                -webkit-transition: all .5s;

                .el-image__error {
                  background-color: #D8D8D8;
                }
              }
            }

            .back-area-item-cont {
              flex: 1;
              margin-left: 10px;
              display: flex;
              flex-flow: column;
              justify-content: space-around;

              .back-area-item-cont-txt {
                font-size: 12px;
                line-height: 18px;

                &.color_2 {
                  color: #999999;
                }

                &.color_3 {
                  color: #EC808D;
                }

                .sign-box {
                  display: inline-block;

                  .sign {
                    display: inline-block;
                    padding: 0 10px;
                    border-radius: 4px;
                    background-color: #eeeeee;
                    margin: 2px 4px 2px 0;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }

              }
            }

            &:hover {
              cursor: pointer;
              box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);

              .el-image {
                transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                -moz-transform: scale(1.1); /* Firefox */
                -webkit-transform: scale(1.1); /* Safari 和 Chrome */
                -o-transform: scale(1.1);
              }
            }
          }
        }
      }
    }

    ::v-deep .el-loading-spinner {
      top: 200px !important;
    }
  }
</style>
